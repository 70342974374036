@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300&display=swap);
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
select {
  outline: none !important;
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(4, 114, 187);
  color: #fefefe;
  flex-direction: column;
}

.loading h1 {
  font-weight: bold;
  font-size: 2.3rem;
}

::-webkit-scrollbar {
  display: none;
}
* {
  scrollbar-width: none;
}

.main {
  display: flex;
  flex-direction: column;
  height: (100vh - 1em);
  height: (100vw - 1em);
  background-color: #eeeeee7d;
}

/* sections page  */

/* History  */

.form {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form .inputs {
  flex: 1 1;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  outline: none;
}

.form .inputs select {
  height: 50px;
  background-color: #fff;
  border: none;
  width: 30%;
  margin-right: 10px;
  border-bottom: 2px solid #aaa;
  outline: none;
}

.form .leftSide {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 50px;
  padding-left: 10px;
}

.leftSide p {
  font-size: 18px;
  color: #000;
  opacity: 0.6;
  font-weight: 500;
}

.filters {
  margin-bottom: 10px;
}

.listContent {
  max-height: 93vh;
}

.pagination {
  max-height: 5vh;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding-right: 100px;

  padding: 10px;
}

/* beneficiaries */
.beneficiaries .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 30px 20px;
}

.beneficiaries .heading select {
  height: 50px;
  background-color: #fff;
  border: none;
  width: 100%;
  margin-right: 10px;
  border-bottom: 1px solid #aaa;
}
.heading div {
  flex: 1 1;
}

.heading .addBtnSection {
  display: flex;
  justify-content: flex-end;
}

.fa {
  margin-right: 8px;
  font-size: 14px;
}

.PhoneInput {
  width: 100% !important;
  margin-top: 10px;
}

.success__page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #f1f1f1;
}

@media only screen and (max-width: 1000px) {
  .success__box {
    width: 70%;
  }
}

.success__box {
  width: 40%;
  border-radius: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-height: 30%;
  padding: 30px;
  border-radius: 10px;
}

.success__title {
  font-weight: bold;
  font-size: 30px;
  margin: 10px;
}

.success_description {
  font-size: 15px;
  font-weight: 500;
  color: #555;
  text-align: center;
  margin-bottom: 10px;
}

.transactions {
  height: 75vh;
  overflow-y: scroll;
}

.accounts {
  padding: 9px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  cursor: pointer;
}

.account {
  margin-right: 20px;
}
.account .details {
  min-width: 450px !important;
}

.verySmall {
  font-size: 11px;
  text-transform: capitalize;
}

.small {
  font-size: 11px;
}
.page-item {
  font-size: 12px;
  margin: 10px;
  padding: 10px;
}

.item--active {
  background-color: #037375 !important;
  font-size: 12px;
  margin: 10px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
}

.hover-class:hover {
  background-color: #037375 !important;
  color: #fff !important;
}

.CustomeUploadTable {
  background: #fff;
  box-shadow: 1px 1px 8px 6px #fafafa;
  padding: 10px;
  border-radius: 10px;
}

.CustomeUploadTable .searchUploadCustome {
  margin: 0px !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  opacity: 1;
}

.CustomeUploadTable .searchUploadCustome {
  height: auto !important;
  background: #fafafa;
  box-shadow: 0px 0px;
  border-radius: 8px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  display: flex;
  align-items: center;
  /* padding-left: 15px; */
}

.CustomeUploadTable
  .MuiPaper-root.ForwardRef-root-1.MuiPaper-elevation1
  input::placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-size: 15px !important;
  font-weight: 400;
}

.CustomeUploadTable input {
  height: 48px !important;
  background: #fafafa;
  box-shadow: 0px 0px;
  border-radius: 8px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border: 0px;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0px;
  outline: 0px;
  box-shadow: 0px 0px transparent;
}

.CustomeUploadTable input:focus {
  border-color: transparent;
  outline: 0;
  background: #fafafa;
  box-shadow: 0px 0px transparent;
}

.CustomeUploadTable .rdt_TableRow {
  grid-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto auto;
  padding: 0px 5px;
}

.CustomeUploadTable .rdt_TableRow .rdt_TableCell > div {
  width: 100%;
}
.CustomeUploadTable .rdt_TableRow .rdt_TableCell:last-child > div {
  text-align: center;
}
.CustomeUploadTable .rdt_TableRow .rdt_TableCell {
  padding: 0px;
}

.CustomeUploadTable .searchUploadCustome input {
  padding: 0px;
  height: auto !important;
  margin: 0px;
}
.deleteIconUpload i:before {
  content: "\f1f8";
}
.CustomeUploadTable .rdt_TableRow .rdt_TableCell:nth-last-child(2) {
  max-width: 50px;
  min-width: 50px;
  justify-content: center;
}

.CustomeUploadTable .rdt_TableRow .rdt_TableCell:nth-last-child(2) > div {
  text-align: center;
}

.CustomeUploadTable .rdt_TableRow .rdt_TableCell:last-child {
  max-width: 50px;
  min-width: 50px;
  justify-content: center;
}

.deleteIconUpload i {
  margin: 0px;
  font-size: 25px;
  color: #434141;
}
.SucessIcon i {
  margin: 0px;
  font-size: 25px;
  color: #7dd062;
}

.ErrorIcon i {
  margin: 0px;
  font-size: 25px;
  color: red;
}
.custom-error input {
  border: 1px solid red;
  padding-right: 30px;
}

.Customeinputparrent input::placeholder {
  font-size: 14px;
}

.custom-error {
  position: relative;
}

.custom-error:before {
  content: "\f05a";
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  font: normal normal normal 20px/1 FontAwesome;
  color: red;
}

.customeTableWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.CustomeAddCell i {
  margin: 0px;
  font-size: 20px;
  color: #434141;
}
/* MakeTransfer css */

.wrapperSwitchAndLable {
  margin: 0px !important;
  padding: 20px 15px;
  border: 1px solid #cccccc;
  display: flex;
  grid-gap: 20px;
  margin-bottom: 10px !important;
}

.wrapperSwitchAndLableNew {
  margin: 0px !important;
  padding: 20px 15px;
  /* border: 1px solid #cccccc; */
  display: flex;
  grid-gap: 20px;
  margin-bottom: 10px !important;
}

.wrapperSwitchAndLableNew .MuiFormControlLabel-label {
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
}

.wrapperSwitchAndLableNew .MuiSwitch-root {
  padding: 0px;
  border-radius: 100px;
  height: 32px;
  width: 55px;
}

.wrapperSwitchAndLableNew .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 2px;
}

.wrapperSwitchAndLableNew
  .MuiSwitch-root
  .MuiSwitch-switchBase
  span.MuiSwitch-thumb {
  width: 28px;
  height: 28px;
}

.wrapperSwitchAndLableNew .MuiSwitch-root .MuiSwitch-track {
  background-color: #e5e5e5;
}

/*  */

.wrapperSwitchAndLable .MuiFormControlLabel-label {
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
}

.wrapperSwitchAndLable .MuiSwitch-root {
  padding: 0px;
  border-radius: 100px;
  height: 32px;
  width: 55px;
}

.wrapperSwitchAndLable .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 2px;
}

.wrapperSwitchAndLable
  .MuiSwitch-root
  .MuiSwitch-switchBase
  span.MuiSwitch-thumb {
  width: 28px;
  height: 28px;
}

.wrapperSwitchAndLable .MuiSwitch-root .MuiSwitch-track {
  background-color: #e5e5e5;
}

.customeDesignwithdraw {
  padding: 0.5rem 1.5rem !important;
}

.withdrawTitle {
  font-size: 15px;
  color: #7f7f7f;
  font-weight: bolder;
}

.customeDesignwithdraw {
  display: grid !important;
}

.bacnkCheckBoxWrapper {
  padding: 11px 21px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox {
  padding: 11px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  grid-gap: 31px;
  background: #ffff;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox input {
  width: 30px;
  height: 30px;
  opacity: 0;
}

.bacnkCheckBoxWrapper label {
  font-size: 15px;
  color: #4c4c4c;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark {
  background: #e6e6e6;
  border-radius: 10px;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark:before {
  content: "\f00c";
  background-color: #067375;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font: normal normal normal 14px/1 FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 30px;
  width: 30px; */
  height: 100%;
  width: 100%;
  background-color: #eee;
  pointer-events: none;
}

.wrapperBankCheckBoxwrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.wrapperBankCheckBoxwrapper:focus-within {
  outline: thick solid rgba(13, 110, 253, 0.25);
}

.innerBankBox {
  padding: 11px 21px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
}

.innerBankBox {
  padding: 0px 11px 0px 11px;
  background: #fafafa;
  border-radius: 10px;
  margin: 0px;
}

.innerBoxDesc {
  font-size: 14px;
  color: #8b8b8b;
  font-weight: 400;
}

.innerBankBox .w-full.mb-1 {
  margin: 10px 0px;
}

.innerBankBox .w-full.mb-1 {
  margin: 0px !important;
}

.innerBankBox .w-full.mb-1 input,
.innerBankBox .bg-white {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fff !important;
}

.owner {
  border: 0px;
  padding: 15px 16px;
  /* padding-left: 96px !important; */
  border-radius: 10px;
  margin: 7px 0px !important;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.innerBankBox .w-full.mb-1 input {
  border: 0px;
  padding: 15px 16px !important;
  padding-left: 96px;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fff !important;
}
.outerfieldBox {
  margin-bottom: 1px !important;
}

.bankNameDropDown,
.customInputStyle {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fafafa !important;
  color: #080707;
  font-size: 14px;
  height: 50px;
}
.customInputStyle:active,
.customInputStyle:hover,
.customInputStyle:focus-visible,
.customInputStyle:focus {
  border: none;
  outline: none;
}

.transferPaymentFormInput {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fafafa !important;
  color: #080707;
  font-size: 14px;
}

.outerfieldBox input,
.outerfieldBox select {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #fafafa !important;
  color: #080707;
  font-size: 14px;
}
.outerfieldBoxWhite input,
.outerfieldBoxWhite select {
  border: 0px;
  padding: 15px 16px !important;
  border-radius: 10px !important;
  margin: 7px 0px !important;
  background: #ffffff !important;
  color: #080707;
  font-size: 14px;
}

.innerBankBox .bg-white {
  background-color: transparent;
}

.innerBankBox .bg-white input {
  border: 0px;
  padding: 0px !important;
  border-radius: 10px !important;
}

.innerBankBox .w-full.mb-1 input::placeholder {
  color: #cacaca;
  font-size: 14px;
}

.innerBankBox .bg-white small {
  color: #414141;
}

.innerBankBox .bg-white:last-child {
  background: #fafafa !important;
  padding: 0px !important;
  grid-gap: 5px;
}

.innerBankBox .bg-white:last-child input {
  background: transparent;
  padding: 15px 16px !important;
  background: #fff;
}

.innerBankBox .bg-white:last-child .px-1 {
  padding: 15px 16px !important;
  background: #fff;
  border-radius: 10px;
  min-height: 50px;
}

.scrollDiv {
  /* max-height: 350px; */
  overflow-y: scroll;
  margin-bottom: 15px;
}

.customeCacheDesign {
  padding: 0px !important;
}
.customeCacheDesign input.default\:ring-2 {
  margin: 0px !important;
}
.marg-0 {
  margin-bottom: 0 !important;
}
.pad-10 {
  padding: 14px !important;
}
.customDesign {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 13px !important;
}

.react-datepicker__month-container {
  width: 178px;
  height: 253px;
  border-radius: 92px;
  border: none !important;
}

.react-datepicker__month {
  height: 524px;
  width: 172px;
  display: flex;
  flex-flow: column;
  grid-gap: 14px;
  gap: 14px;
}

.react-datepicker {
  box-shadow: 0 0 20px 2px #00000054;
  border-radius: 20px;
  border: none !important;
}

.react-datepicker__input-container input {
  background: transparent !important;
}

.gray-datepicker {
  background: #fafafa !important;
  box-shadow: none !important;
}

.bacnkCheckBoxWrapper {
  padding: 11px 21px;
  background: #fafafa;
  border-radius: 10px;
  margin: 10px 0px;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox {
  padding: 11px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  grid-gap: 31px;
  background: #ffff;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 30px;
  width: 30px; */
  height: 100%;
  width: 100%;
  background-color: #eee;
  pointer-events: none;
}

.bacnkCheckBoxWrapper .wrapperBankCheckBox input {
  width: 30px;
  height: 30px;
  opacity: 0;
}

.bacnkCheckBoxWrapper label {
  font-size: 15px;
  color: #4c4c4c;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark {
  background: #e6e6e6;
  border-radius: 10px;
}

.bacnkCheckBoxWrapper input:checked ~ .checkmark:before {
  content: "\f00c";
  background-color: #067375;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font: normal normal normal 14px/1 FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;
}

.wrapperBankCheckBoxwrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.otherRowStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}
.otherRowStyle .bacnkCheckBoxWrapper {
  width: 100%;
}
.otherRowStyle .outerfieldBox {
  border-radius: 10px;
  margin-bottom: 0px !important;
}
.otherRowStyle .bacnkCheckBoxWrapper .wrapperBankCheckBox .outerfieldBox input {
  padding: 0 !important;
  opacity: 1;
  width: 100%;
  background-color: transparent !important;
  margin: 0px !important;
}

.otherRowStyle .outerfieldBox label {
  display: none;
}

.customcheckbox {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  outline: none;
  position: relative;
  cursor: pointer;
  display: block;
  /* background-color: #fafafa; */
  background-color: #0000004d;
}

.customcheckbox:checked {
  background-color: #007a7e;
  position: relative;
}

.customcheckbox:checked::before {
  font-family: FontAwesome;
  content: "\f00c";
  color: white;
  font-size: 16px;
  position: absolute;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* withdraw checkbox */
.withdrawcheckbox {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.313rem;
  outline: none;
  position: relative;
  cursor: pointer;
  display: block;
  /* background-color: #fafafa; */
  background-color: #ebebeb;
}

.withdrawcheckbox:checked {
  background-color: #007a7e;
  position: relative;
}

.withdrawcheckbox:checked::before {
  font-family: FontAwesome;
  content: "\f00c";
  color: white;
  position: absolute;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* signup shadow */
.shadow-c {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}

/* overiding custom body of react data table component */

.tableWrapper .rdt_TableBody {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06) !important;
  border-radius: 0.75rem !important;
  overflow: hidden;
}

/* remove autofill color of chrome for input */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.conHeight {
  height: calc(100vh - 56px);
  margin-top: 56px !important;
  padding: 8px !important;
  overflow-y: scroll !important;
}

.iconsMenu {
  width: 60px;
}

.menu {
  width: 220px;
  padding: 6px;
}

.withMenu {
  width: calc(100vw - 220px);
  padding: 10px;
}

.withIconsMenu {
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
  margin-block: 50px;
}

.myLink {
  margin-bottom: 8px;
  font-family: "Poppins", sans-serif;
  color: #666;
}

.myLink:hover {
  border: 0.5px solid #037375 !important;
  color: #037375;
  margin-bottom: 8px;
  transition: all 300s;
}

/* mouse over link */
a:hover {
  color: #037375 !important;
  transition: all 300s;
}

/* selected link */
a:active {
  color: #037375 !important;
  transition: all 300s;
}

.active_menu {
  cursor: pointer;
  color: #037375 !important;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
}

.in_active_menu {
  cursor: pointer;
  color: #777;
  font-weight: 400;
  transition: 0.1s ease-in-out;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  font-weight: bold;
  background-color: #fff;
}

.in_active_menu:hover {
  border: 1px solid #fafafa;
  padding: 10px 10px;
  background-color: #fafafa;
}

.in_active_menu > small:hover,
i:hover {
  color: #0373758d !important;
}

.bgContent {
  background-color: #fafafa !important;
}

.transactions{
    flex:2 1;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

section > h2 {
    font-size: 1.5rem;
    font-weight: medium;
}


.transaction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 0 10px;
    transition: all .4s;
    color:#333;
    font-size: 13px;
}

.transaction + .transaction {
    border-top:1px solid #eee;
}

.transaction  img {
    height: 48px;
    width: 48px;
    border-radius: 40%;
}

.transaction:hover{
  background-color: #eee;
  color: #000;
}


.transaction div {
    text-transform: capitalize;
}

.sender_circle {
 height: 50px;
 width: 50px;
background-color: rgba(140, 232, 74, 0.32); 
 border-radius: 25px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.sender_circle > i {
    color: rgb(23, 186, 8);
}

.receiver_circle {
    height: 50px;
    width: 50px;
    background-color: rgba(140, 232, 74, 0.32);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.receiver_circle > i {
    color: rgb(23, 186, 8);
}

.sender_circle .fa, .receiver_circle .fa {
    margin-right: 0 !important;
    font-weight: lighter !important;
}

.addModal {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.closeBtn {
  cursor: pointer;
  float: right;
  margin-top: 5px;
  width: 40px;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.6);
}

.react-confirm-alert-overlay {
  z-index: 1500 !important;
}

@media only screen and (max-width: 400px) {
  .react-confirm-alert {
    width: 100vw;
  }
}

@media only screen and (max-width: 700px) {
  .bank-container {
    padding: 10px;
  }

  .mainbar {
    display: inline-block;
    text-align: center;
  }

  .addBankBtn {
    margin-top: 20px;
  }

  .modalBackground {
    zoom: 0.7;
  }

  .ibanNumber {
    font-size: 0.9rem;
  }

  .contentContainer {
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .rightPart {
    margin-top: 10px !important;
  }
}



.status, .details, .head{
    padding: .6em;
    border-radius: 10px;
}

/* statuses */

.statuses{
    display: flex;
    flex-direction: row;
  }
  
  .statuses .date{
    flex: 1 1;
    text-align: right;
    margin-right: 20px;
    font-weight: bold;
    opacity: .6;
  }
  
  .statuses .date div{
    margin:10px;
    padding: 8px;
  
  }
  
  .statuses .statusBox{
  flex:3 1
  }
  
  
  .statuses .statusBox div {
    margin:8px;
    padding: 10px;
    border-left: 6px solid rgb(202, 206, 206);
  }
  
  .status-passed{
    border-left: 6px solid rgb(18, 165, 233) !important;
  
  }
  

